// Dropzone

.dropzone-wrapper {
  width: 101%;
  height: 230px;
  border: $gray-400 dashed 2px;
  @include border-radius($border-radius-lg);
  display: flex;
  align-items: center;
  align-content:center;
  text-align: center;
  transition: all .2s;

  &:hover {
    border-color: $primary;
  }

  & > div {
    width: 100%;
    height: 100%;
    outline: none !important;
    display: flex;
    align-items: center;
  }

  &.dropzone-wrapper-lg {
    height: 300px;
  }

  &.dropzone-wrapper-sm {
    height: 150px;
  }

  input {
    width: 100%;
    height: 100%;
  }

  .dropzone-content {
    margin: 0 auto;
    color: $gray-600;
  }

  &[aria-disabled="true"] {
    border-color: $gray-300;

    .dropzone-content {
      color: $gray-400;
    }
  }
}

.accepted-files-wrapper {
  .file-list {
    width: 100%;
    text-align: left;
    
    .file-list__header {
      font-size: 0.6875rem;
      letter-spacing: 1px;
      line-height: 1;
    }
    
    .file-list__file {
      &:nth-child(odd) {
        background: #f8f8f8;
      }
    }
  
    .file-list__file-action {
      font-size: $font-size-xs;
      border: none;
      background: none;
  
      &.remove {
        color: $danger;
      }

      &.play,
      &.pause {
        color: $primary;
      }
    }
    
    th {
      user-select: none;
      border-bottom: 1px solid #efefef;
      padding: 1rem;
      color: lighten($black, 50%);
  
      &:hover {
        cursor: pointer;
        color: lighten($black, 20%);
      }
    }
    
    td {
      padding: 0.44rem 1rem;
      color: lighten($black, 50%);
  
      &:hover {
        color: lighten($black, 20%);
      }
    }
  }
}

.uploaded-thumb-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16;

  .uploaded-thumb-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    flex-flow: column wrap;
  }

  .uploaded-thumb-image-wrapper {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    width: 144px;
    height: 144px;
    padding: 4px;
    box-sizing: border-box;
  
    .uploaded-thumb-image-inner {
      position: relative;
      display: flex;
      flex-direction: row;
      min-width: 0;
      overflow: hidden;
    }
  }

  .uploaded-thumb-info-wrapper {
    width: 100%;
  }

  .uploaded-thumb-remove {
    position: absolute;
    top: 0;
    right: 0;
  }
}