/*Styles for the body of the table */

.react-collapsible-bs4 {
  overflow: hidden;
}

.react-collapsible-bs4 table.react-collapsible {
  border-collapse: collapse;
  width: 100%;
}

.react-collapsible-bs4 .react-collapsible thead,
.react-collapsible-bs4 .react-collapsible .child-label {
  color: #555;
  text-align: left;
  font-weight: 600;
}

.react-collapsible-bs4 .react-collapsible thead th {
  padding: 1rem .8rem;
}

.react-collapsible-bs4 .react-collapsible td {
  min-width: 10rem;
  padding: 0 .8rem;
}

.react-collapsible-bs4 .react-collapsible .clickable {
  cursor: pointer;

  span {
    float: right;
  }
}

.react-collapsible-bs4 .react-collapsible tr {
  border-bottom: 0.1rem #e0e4ea solid;
}

.react-collapsible-bs4 .react-collapsible td {
  padding: .8rem .8rem .8rem 0;
  font-weight: 200;
}


/*child row in table */

.react-collapsible-bs4 .react-collapsible .child-cell {
  display: flex;
}

.react-collapsible-bs4 .react-collapsible .child-label {
  margin-left: 1.6rem;
  flex-basis: 25%;
}

.react-collapsible-bs4 .react-collapsible .child-content {
  flex-basis: 75%;
}

@media screen and (min-width: 768px) {
  .react-collapsible-bs4 .react-collapsible .child-label {
      margin-left: 1.6rem;
      flex-basis: 10%;
  }
  .react-collapsible-bs4 .react-collapsible .child-content {
      flex-basis: 90%;
  }
}


/*Styles for search */

.react-collapsible-bs4 .react-collapsible-search {
  margin: 3.2rem 0;
}

.react-collapsible-bs4 .react-collapsible-search .react-collapsible-clear {
  border: 0;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
}

.react-collapsible-bs4 .react-collapsible-search input {
  border: 0;
  border-bottom: 1px #009cde solid;
  width: 100%;
  height: 2rem;
  font-weight: 200;
}

.react-collapsible-bs4 .react-collapsible-search input::placeholder {
  color: #858DA3;
}

.react-collapsible-bs4 .react-collapsible-search .search-input {
  display: inline-block;
  position: relative;
}

.react-collapsible-bs4 .react-collapsible-search .search-input .search-icon {
  position: absolute;
  right: -2px;
  top: 0;
}

.react-collapsible-bs4 .brand-primary-light {
  color: #009cde;
}

.react-collapsible-bs4 .search-highlight {
  background-color: #C1C9D5;
}


/*Styles for pagination */

.react-collapsible-bs4 .react-collapsible-page {
  font-weight: 200;
}

.react-collapsible-bs4 .react-collapsible-page .arrow-left {
  margin-right: .8rem;
}

.react-collapsible-bs4 .react-collapsible-page .arrow-right {
  margin-left: .8rem;
}

.react-collapsible-bs4 .highlight {
  background-color: #FFFF00
}