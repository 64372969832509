.formBuilder div[data-test='form-head'] {
  width: 100% !important;
  border: 1px solid rgba(32, 39, 140, 0.125) !important;
  background-color: #fff !important;
  box-shadow: 0 0.46875rem 2.1875rem rgb(8 10 37 / 3%), 0 0.9375rem 1.40625rem rgb(8 10 37 / 3%), 0 0.25rem 0.53125rem rgb(8 10 37 / 5%),
    0 0.125rem 0.1875rem rgb(8 10 37 / 3%) !important;
}

.formBuilder div[data-test='form-head'] div {
  width: 49% !important;
}

.formBuilder .form-body .card-container {
  width: 100% !important;
  border: 1px solid rgba(72, 78, 165, 0.125) !important;
}

.formBuilder .form-body .card-container .d-flex {
  border-bottom: 1px solid rgba(72, 78, 165, 0.125) !important;
}

.formBuilder .form-body .card-container h4 {
  color: #000 !important;
  font-size: 16px !important;
}

.formBuilder .form-body .card-container:hover {
  border: 1px solid #fff !important;
  box-shadow: 0 0.46875rem 2.1875rem rgb(8 10 37 / 3%), 0 0.9375rem 1.40625rem rgb(8 10 37 / 3%), 0 0.25rem 0.53125rem rgb(8 10 37 / 5%),
    0 0.125rem 0.1875rem rgb(8 10 37 / 3%) !important;
}

.formBuilder .toggle-collapse .fa-caret-right {
  width: 8px !important;
}

.formBuilder .toggle-collapse .fa-caret-down {
  width: 12px !important;
}

.formPreview .btn.btn-info {
  display: none !important;
}

.formPreview .form-group .control-label {
  font-weight: bold !important;
}

.formPreview legend {
  font-size: 20px;
}

.formPreview .field-radio-group .radio label span span,
.formPreview .field-array .checkbox label span span {
  margin-left: 10px;
  display: block;
  float: left;
}

.formPreview input[type='radio'],
.formPreview input[type='checkbox'] {
  box-sizing: border-box;
  width: 15px;
  height: 15px;
  margin-top: 5px;
  display: block;
  float: left;
}

.w-170px {
  width: 170px;
}

svg > g > g:last-child {
  /* pointer-events: none !important;*/
}

/*todo: classname selector*/
div[style*='height: 350px; width: 100%;']:nth-of-type(11n) g path {
  display: none;
}

div[style*='height: 350px; width: 100%;']:nth-of-type(11n) g path[fill='#0011cc'],
div[style*='height: 350px; width: 100%;']:nth-of-type(11n) g path[fill='#cccccc'] {
  display: block !important;
}

.overflow-auto--400px .overflow-auto {
  max-height: 400px;
}

.overflow-auto--300px .overflow-auto {
  max-height: 300px;
}

.formBuilder .alert {
  display: none !important;
}

.goog-tooltip {
  z-index: 1000 !important;
}

.customDisabled {
  background-color: #eff0f3;
}

.eee {
  padding: 0px;
}
.customCompleted {
  background-color: #3ac47d;
}
.customDanger {
  background-color: #ff4747;
}

.customPrimary {
  background-color: #30b1ff;
}
.customTextAlignWebkitCenter {
  text-align: -webkit-center;
}

.ReactTags__tags {
  position: relative;
}
.ReactTags__selected {
  padding: 0.3rem;
}
/* Styles for the input */
.ReactTags__tagInput {
  width: 100%;
  border-radius: 2px;
  display: inline-block;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
}

/* Styles for selected tags */
.ReactTags__selected span.blue {
  border: 1px solid #ddd;
  background: #0c8ef8;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 3px;
  font-weight: bold;
}
.ReactTags__selected span.red {
  border: 1px solid #ddd;
  background: #db0f0f;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 3px;
  font-weight: bold;
}
.ReactTags__selected span.green {
  border: 1px solid #ddd;
  background: #3ac47d;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 3px;
  font-weight: bold;
}

.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 100%;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}
