$weather-icons: "01d", "02d", "03d", "04d", "09d", "10d", "11d", "13d", "50d";

.weather-icon {
  width: 22px;
  height: 22px;
  display: inline-block;
  background-size: 180%;
  background-position: center;
  background-repeat: no-repeat;

  @each $icon in $weather-icons {
    &-#{$icon} {
      background-image: url('../../utils/images/weathers/#{$icon}.png');
    }
  }
}
